import Image from 'next/image';
import chabadlogo from '@/images/chabad.png';

import { useSiteInfo } from '@/context/SiteInfoContext';

const LoginChabad = ({ submitLoginWithChabad }) => {
  const { siteInfo } = useSiteInfo();

  return (
    <>
      <button
        type="button"
        onClick={submitLoginWithChabad}
        className="chabadButton"
      >
        <Image
          src={chabadlogo}
          alt="Chabad logo"
          width={20}
          height={20}
          style={{ display: 'inline-block' }}
        />{' '}
        LOGIN WITH CHABAD
      </button>

      {siteInfo.login_method.length > 1 && (
        <span className="divider">
          <hr />
          <label>OR</label>
        </span>
      )}
    </>
  );
};
export default LoginChabad;
